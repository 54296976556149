import domReady from '@roots/sage/client/dom-ready';
import Splide from '@splidejs/splide';
import { Fancybox } from "@fancyapps/ui";
import { debounce } from 'lodash';


class Counter {
  constructor(element, options) {
      this.element = element;
      this.options = { ...{
          origin: 0,
          target: 100,
          type: 'numeric', // 'numeric', 'bar', 'both'
          duration: 1500,
          suffix: null,
          classes: {
              bar: 'counter-bar',
              number: 'counter-number'
          }
      }, ...options }
      this.init();
  }
  init() {
      document.querySelectorAll(this.element).forEach((element) => {
          const attributes = Object.keys(element.dataset)
              .filter(key => key.startsWith('counter'))
              .reduce((obj, key) => {
                  obj[key.replace('counter', '').toLowerCase()] = key.includes('Origin') || key.includes('Target') || key.includes('Duration') ? +element.dataset[key] : element.dataset[key];
                  return obj;
              }, {});
          const options = { ...this.options, ...attributes };
          this.run(element, options);
          document.addEventListener('scroll', this.run.bind(this, element, options));
      });
  }
  run(element, options) {
      const visibleTop = element.offsetTop - window.innerHeight;
      const visibleBottom = element.offsetTop + element.clientHeight;
      if (
          window.pageYOffset < visibleTop ||
          window.pageYOffset > visibleBottom ||
          element.hasAttribute('data-counter-complete')
      ) { return; }

      if (options.type == 'numeric') {
          this.startNumeric(element, options);
      } else if (options.type == 'bar') {
          this.startBar(element, options);
      } else if(options.type == 'both') {
          Object.values(options.classes).forEach(value => {
              if (!element.querySelector(`.${value}`)) {
                  element.innerHTML += `<div class="${value}"></div>`;
              }
          });
          this.startNumeric(element, options, true);
          this.startBar(element, options, true);
      }

      element.setAttribute('data-counter-complete', 'true');
  }
  startNumeric(element, options, child = false) {
      if (child) {
          element = element.querySelector(`.${options.classes.number}`);
      }

      const origin = +element.innerText || options.origin;
      let start;
      const step = (timestamp) => {
          start = start || timestamp;
          const progress = Math.min((timestamp - start) / options.duration, 1);
          element.innerHTML = Math.floor(progress * (options.target - origin) + origin) + options.suffix;
          if (progress < 1) {
              window.requestAnimationFrame(step);
          } else {
              window.cancelAnimationFrame(window.requestAnimationFrame(step));
          }
      }
      window.requestAnimationFrame(step);
  }
  startBar(element, options, child = false) {
      if (child) {
          element = element.querySelector(`.${this.options.classes.bar}`);
      }

      element.animate([
          { width: `${options.origin * 100 / options.target}%` },
          { width: '100%' }
      ], {
          duration: options.duration,
          iterations: 1
      });
  }
}


/**
 * Application entrypoint
 */
domReady(async () => {

  AOS.init();

  const loadMoreButton = document.getElementById('load-more-posts');
  const postWrapper = document.querySelector('.post-wrapper');
  const spinner = document.querySelector('.loading-spinner');

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', debounce(loadMorePosts, 0));
  }

  function loadMorePosts() {
    const postType = document.getElementById('post-type').value;
    const postCategory = document.getElementById('term_id').value;
    console.log(postCategory);
    spinner.classList.remove('opacity-0');
    const xhr = new XMLHttpRequest();
    const nextPage = loadMoreButton.dataset.nextPage;

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);

        if (response.success) {

          postWrapper.insertAdjacentHTML('beforeend', response.data);

          if (!response.hasMore) {
            loadMoreButton.style.display = 'none';
          } else {
            loadMoreButton.dataset.nextPage = parseInt(nextPage) + 1; // Increase nextPage value by 1
          }

          spinner.classList.add('opacity-0');
        }
      }
    };

    xhr.open('GET', `/wp-json/load-more/v1/posts?page=${nextPage}&postType=${postType}&postCategory=${postCategory}`, true);
    xhr.send();
  }



  // let fitlters = document.querySelectorAll('.single-filter-input');
  // fitlters.forEach((element) => {
  //   element.addEventListener('change', (e) => {
  //     if(element.checked) {
  //       element.parentNode.classList.add('!bg-primary');
  //     } else {
  //       element.parentNode.classList.remove('!bg-primary');
  //     }
  //   });
  // });


  let galleryImage = document.querySelectorAll('.wp-block-gallery .wp-block-image');

  if (galleryImage.length > 0) {
    galleryImage.forEach((element) => {
      let image = element.querySelector('img');
      let imageLink = image.getAttribute('src');

      // Create the <a> element
      let anchorElement = document.createElement('a');
      anchorElement.setAttribute('href', imageLink);
      anchorElement.setAttribute('data-fancybox', 'gallery');
      // Wrap the image with the <a> element
      element.insertBefore(anchorElement, image);
      anchorElement.appendChild(image);


    });
  }

  Fancybox.bind("[data-fancybox]", {});

  let counters = document.querySelectorAll('.counter b');

  if(counters.length > 0 ) {
    new Counter('.counter b');
  }

  const menuButton = document.getElementById('menu-button');
  const menu = document.getElementById('menu-container');
  const body = document.querySelector('body');

  menuButton.addEventListener('click', () => {
    menu.classList.toggle('opacity-0');
    menu.classList.toggle('invisible');
    menuButton.classList.toggle('open');
    body.classList.toggle('overflow-hidden');
  });

  const gotopLink = document.getElementById("gotop");

  gotopLink.addEventListener("click", function(event) {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  let menuParent = document.querySelectorAll('.menu-item-has-children > a');
  menuParent.forEach((element) => {
    let arrow = document.createElement('span');
    arrow.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="30.297" height="30.297" viewBox="0 0 30.297 30.297"> <path id="arrow-down-right-svgrepo-com" d="M36.616,12.019a2,2,0,0,0-3.993,0V29.8L9.676,6.855A2,2,0,0,0,6.855,9.676L29.8,32.623H12.019a2,2,0,0,0,0,3.993H34.752a1.994,1.994,0,0,0,.772-.16,2.023,2.023,0,0,0,1.091-1.7Z" transform="translate(-6.319 36.616) rotate(-90)" fill="#5bc4f1"/> </svg>';
    element.appendChild(arrow);
  });

  let menuArrow = document.querySelectorAll('.menu-item-has-children > a[href="#"]');
  menuArrow.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      element.parentElement.classList.toggle('open');
    });
  });

  var elms = document.getElementsByClassName( 'splide' );
  for ( var i = 0; i < elms.length; i++ ) {
    new Splide( elms[ i ], {
      'pagination': false,
      'type': 'loop',
      'autoplay': true,
      'interval': 3000,
      'padding': '15%',
    } ).mount();
  }

  const pressQuestions = document.querySelectorAll('.accordion-button');
  for (let i = 0; i < pressQuestions.length; i++) {
    pressQuestions[i].addEventListener('click', () => {
      const pressAnswer = pressQuestions[i].nextElementSibling;
      const isActive = pressQuestions[i].classList.contains('active');
      if (isActive) {
        pressQuestions[i].classList.remove('active');
        pressAnswer.style.maxHeight = null;
      } else {
        pressQuestions[i].classList.add('active');
        pressAnswer.style.maxHeight = pressAnswer.scrollHeight + "px";
      }
    });
  }

  const link = document.querySelectorAll('.js-show-modal');
  for (let i = 0; i < link.length; i++) {
    link[i].addEventListener('click', function (event) {
      event.preventDefault();
      const personId = link[i].dataset.person;
      var xhr = new XMLHttpRequest();
      xhr.open('POST', ajax_url, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      xhr.onload = function() {
        if (xhr.status === 200) {
          var personData = JSON.parse(xhr.responseText);
          buildPersonsModal(personData)
        } else {
          console.error('Error retrieving person data');
        }
      };
      xhr.send('action=get_person_data&person_id=' + personId);
    });
  }

  function buildPersonsModal($data) {
    let modalWrapper      = document.getElementById('persons-modal'),
        personName        = $data['name'],
        personPosition    = $data['position'],
        personImage       = $data['image'],
        personBio         = $data['content'],
        personCountry     = $data['country'],
        personEmail       = $data['email'],
        personPhone       = $data['phone'];
    let template  = ` <div class="bg-primary z-[100] fixed h-100vh overflow-auto pb-20 inset-0 overvlow-auto ">
                  <div class="relative container 2xl:max-w-[1370px] py-6">
                      <button class="js-close-person-modal flex open absolute z-30 top-[18px] right-[30px] font-bold group hover:bg-secondary transition ease-in-out text-lg md:text-xl duration-500 text-primary items-center rounded-full bg-white py-3 px-6 md:py-6 md:px-8">
                          Close
                          <div class="hamburger flex flex-col gap-[7px] ml-4">
                            <div class="w-5 h-[4px] group-hover:w-10 rounded-full bg-primary transition-all ease-in-out duration-500"></div>
                            <div class="w-10 h-[4px] rounded-full bg-primary transition ease-in-out duration-500"></div>
                            <div class="w-10 h-[4px] rounded-full bg-primary transition ease-in-out duration-500"></div>
                          </div>
                      </button>
                  </div>
                  <div class="container">
                      <div class="flex flex-wrap pt-44">
                          <div class="w-full mb-10 md:mb-0 md:w-4/12 md:pr-16">
                              <div class="bg-white rounded-3xl mb-10 p-8">
                                  <img class="rounded-3xl mb-6" src="${personImage}">
                                  <div>
                                      <div class="text-primary mb-1 leading-tight text-2xl font-bold mt-6">
                                        ${personName}
                                      </div>
                                      <div class="text-base font-bold text-primary leading-tight">
                                        ${personPosition}
                                      </div>
                                      <div class="text-secondary font-bold text-base leading-tight">
                                        ${personCountry}
                                      </div>
                                  </div>
                              </div>
                              <a href="#" class="w-full inline-flex relative bg-secondary ease-in-out transition hover:bg-white text-xl lg:text-2xl font-bold rounded-full text-primary justify-center items-center p-4 lg:p-6 lg:px-10">
                                  Contact +
                              </a>
                          </div>
                          <div class="w-full mt-10 md:mt-0 md:w-8/12">
                              <div class="text-white leading-9 text-xl">
                                ${personBio}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>`;

    modalWrapper.innerHTML = template;

    const closeButton = modalWrapper.querySelector('.js-close-person-modal');
    closeButton.addEventListener('click', function() {
      modalWrapper.innerHTML = '';
    });
  }

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
